.wrapper,
.leaderboard {
   display: flex;
   justify-content: center;
   margin-top: 3rem;
}

.Heading {
   font-family: 'Stylish';
   font-size: 5rem;
   text-transform: uppercase;
   color: white;
   text-align: center;
   letter-spacing: 10px;
}

.HeadingContainer {
   position: relative;
   top: 17%;
}

.layout {
   position: relative;
   top: 27%;
   background-color: rgba(255, 255, 255, 0.2);
   width: fit-content;
   left: 50%;
   transform: translateX(-50%);
   border-radius: 10px;
   padding: 1rem;
}
.form_div {
   width: 100%;
   background-color: rgba(255, 255, 255, 0.15);
   border-radius: 10px;
   backdrop-filter: blur(10px);
   border: 2px solid rgba(255, 255, 255, 0.1);
   box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
   padding: 50px 35px;
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: var(--mb-6);
}
.form_div * {
   color: #ffffff;
   letter-spacing: 0.5px;
   outline: none;
   border: none;
}
.form_div h3 {
   font-size: 26px;
   text-align: center;
}

.labl {
   display: block;
   margin-top: 30px;
   font-size: 16px;
   font-weight: 500;
   text-align: left;
}

.instruction {
   padding-bottom: 5%;
}

::placeholder {
   color: #e5e5e5;
}

.btn_o {
   font-family: 'Primary', sans-serif;

   margin-top: 18px;
   width: 100%;
   background-color: #b22222;
   color: whitesmoke;
   padding: 10px 0;
   font-size: 1rem;
   font-weight: 600;
   border-radius: 5px;
   cursor: pointer;
   text-transform: uppercase;
   text-align: center;
}

.form_head {
   margin-bottom: 5%;
   text-transform: uppercase;
}

@media only screen and (max-width: 1023px) {
   .form_div {
      top: 65%;
      width: 90%;
   }
   .wrapper,
   .leaderboard {
      margin-top: 1.3rem;
   }
}
