.question_page {
   width: 75%;
   margin-inline: auto;
   margin-top: 5rem;
   display: flex;
   flex-direction: column;
   /* align-items: flex-start; */
}
.full_card {
   background-color: rgba(255, 255, 255, 0.2);
   /* margin: auto; */
   /* width: 90%; */
   padding: 30px;
   border-end-end-radius: 15px;
   border-end-start-radius: 15px;
   border-top-right-radius: 15px;
   color: #fff;
   border: 5px solid #fff;
   position: relative;
}
.tab_bar {
   margin: auto;
   width: 90%;
   display: flex;
   flex-wrap: wrap;
   font-size: 1.1rem;
}

.tabs_1 {
   background-color: rgba(255, 255, 255, 0.2);
   color: #fff;
   padding: 8px;
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   margin-right: 7px;
   cursor: pointer;
}
.tabs_2 {
   background-color: rgba(255, 255, 255, 0.2);
   color: #fff;
   padding: 8px;
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   cursor: pointer;
}
.tabBtn {
   font-family: 'Stylish', sans-serif;
   display: inline-block;
   border-top-left-radius: 1rem;
   border-top-right-radius: 1rem;
   padding: 1rem;
   background-color: rgba(255, 255, 255, 0.2);
}
.tabBtn:hover {
   cursor: pointer;
}
.activeTab {
   /* padding: 6px; */
   /* margin-right: 5px; */
   color: #fff;
   border-bottom: none;
   /* transform: scaleY(1.1); */
   background-color: #fff;
   color: #333;
   font-weight: bold;
}
.questions {
   text-align: center;
   font-size: 1.2rem;
   width: 80%;
   margin: auto;
   margin-bottom: 25px;
}
.card_level {
   font-family: 'Stylish', sans-serif;
   text-align: center;
   font-size: 3rem;
   text-transform: uppercase;
   margin: 2px;
   margin-bottom: 25px;
}

.card_status {
   text-align: center;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 1rem;
}

.card_attempts {
   text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.card_attempts > h4 {
   font-size: 1.2rem;
   margin-bottom: 0.5rem;
}

.card_attempts_cp {
   width: 4rem;
   height: 4rem;
}

.card_score {
}

.card_score > h3 {
   font-size: 2.4rem;
   font-family: 'Stylish', sans-serif;
}

.card_score > h4 {
   font-size: 1.2rem;
}

.card_instructions {
   text-align: center;
   font-size: large;
   margin: 2px;
}
.card_input_field {
   text-align: center;
   margin: 5px;
}
.input_tag {
   width: 65%;
   text-align: center;
   height: 30px;
}
/* .card_buttons {
   display: grid;
   width: 60%;
   margin: auto;
   margin-top: 10px;
   margin-bottom: 10px;
   grid-template-columns: auto auto;
   justify-content: space-around;
} */
.card_buttons {
   display: flex;
   width: 60%;
   margin-inline: auto;
   justify-content: space-around;
   align-items: center;
}
.button {
   font-family: 'Primary';
   border: none;
   border-radius: 5px;
   width: 75px;
   height: 50px;
   color: white;
   cursor: pointer;
}

.buttonClue {
   font-family: 'Stylish', sans-serif;

   background-color: #f44336;
}

.buttonSubmit {
   font-family: 'Stylish', sans-serif;
   background-color: #4caf50;
}

.buttonClue:hover {
   background-color: #da493f;
   color: white;
}

.buttonSubmit:hover {
   background-color: #46cd4b;
   color: white;
}

.clueContainer {
   text-align: center;
   margin-top: 1rem;
   margin-inline: auto;
   width: 80%;
   font-family: 'Stylish';
}
.gameOver {
   border: 2px solid white;
   background-color: rgba(255, 255, 255, 0.2);
   color: white;
   padding: 3rem;
   width: 70%;
   margin-inline: auto;
   border-radius: 10px;
   margin-block: 5rem;
}
.gameOver > h1 {
   font-family: 'Stylish';
   font-size: 3rem;
   text-transform: uppercase;
   color: white;
   text-align: center;
   letter-spacing: 4px;
}

.gameOver > p {
   margin-block: 1rem;
   font-size: 1.3rem;
}
@media only screen and (max-width: 992px) {
   .gameOver {
      width: 100%;
      font-size: 1rem;
   }
   .gameOver > h1 {
      font-size: 1.5rem;
   }
   .question_page {
      width: 95%;
   }
}

@media only screen and (max-width: 768px) {
   .question_page {
      width: 95%;
   }
   .gameOver {
      width: 100%;
      font-size: 1rem;
   }
   .gameOver > h1 {
      font-size: 1.5rem;
   }

   .card_level {
      font-size: 2rem;
      margin-inline: 1rem;
   }

   .card_attempts > h4 {
      font-size: 1rem;
      margin-bottom: 0.5rem;
   }

   .card_attempts_cp {
      width: 3rem;
      height: 3rem;
   }
   
   .card_score > h3 {
      font-size: 1.8rem;
   }
   
   .card_score > h4 {
      font-size: 1rem;
   }
   
}
