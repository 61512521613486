.wrapper {
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
   min-height: 100vh;
   width: 100%;
}

.text_box {
   color: #fff;
}

.link {
   text-decoration: none;
    font-size: 1rem;
    background-color: #fff;
    color: #000;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
   .wrapper {
      flex-direction: column;
      justify-content: center;
   }
   .image_box,
   .text_box {
      width: 75% !important;
   }
}

@media only screen and (max-width: 767px) {
   .wrapper {
      flex-direction: column;
      justify-content: center;
   }

   .image_box,
   .text_box {
      width: 75% !important;
   }
}

.image_box,
.text_box {
   width: 40%;
   text-align: center;
}

.image_box {
   display: flex;
   justify-content: center;
}

.text_box * {
   margin: var(--mb-2) 0;
}

.image_box > img {
   width: 75%;
   height: 75%;
}

.text_box > h1 {
   color: white;
   font-size: 2.5rem;
   font-family: 'Stylish';
   text-transform: uppercase;
   letter-spacing: 0.2rem;
}

.text_box > p {
   font-size: 1.5rem;
}
