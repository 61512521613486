.wrapper {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   min-height: 100vh;
   width: 90%;
}

.box {
   color: #fff;
   background-color: rgba(255, 255, 255, 0.2);
   padding: 2rem;
   text-align: center;
   border-radius: 1rem;
   font-size: 2rem;
}

.box > h1 {
   font-size: 5rem;
   margin: 0px auto;
}
