.leftcircle {
  cursor: pointer;
  position: absolute;
  left: 40px;
  top: 45%;
  color: white;
  border-radius: 50%;
  box-shadow: 0 0 3px black;
  font-size: 2rem;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}
.leftcircle:hover {
  cursor: pointer;
  transform: scale(1.2);
}
.rightcircle {
  cursor: pointer;
  position: absolute; 
  right: 40px;
  top: 45%;
  color: white;
  border-radius: 50%;
  box-shadow: 0 0 3px black;
  font-size: 2rem;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}
.rightcircle:hover {
  cursor: pointer;
  transform: scale(1.2);
}
.slider {
  padding: 30px 0;
  /* height: 120vh; Can Adjust Accordingly */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slide {
  opacity: 0;
  transition-duration: 1s ease;
}
.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image {
  width: 800px;
  height: 600px;
  object-fit: contain;
}
/* .text { */
  /* padding: 0 var(--mb-2) var(--mb-2); */
  /* padding: var(--mb-2) 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--bg);
}

.title {
  font-size: 1.2rem;
  font-weight: 800;
}

.subtitle {
  font-size: small;
} */
.dots {
  margin-top: var(--mb-1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 3px solid white;
  margin: 0 5px;
  cursor: pointer;
  transition: 0.3s;
}
.dot:hover {
  border: 3px solid white;
  width: 12.5px;
  height: 12.5px;
  margin: 0 10px;
}
.dot.active {
  border: 3px solid white;
  background-color: white;
  width: 12.5px;
  height: 12.5px;
}
/* Media Queries */
@media only screen and (max-width: 1023px) {
  .slider {
    padding: 10px 10px 50px 10px;
  }
  /* .text { */
    /* width: 70vw;  Can Adjust Accordingly */
    /* font-size: var(--mb-2);
    box-shadow: 0 0 20px white;
  } */
  .image {
    width: 300px;
    height: 400px;
  }
  .card {
    box-shadow: none;
    /* width: 80%; Can Adjust Accordingly */
    /* width: 90vw; */
    height: 50%;
  }
  .leftcircle {
    display: none;
  }
  .rightcircle {
    display: none;
  }
  .dot {
    width: 10px;
    height: 10px;
    /* border: 2px solid var(--text); */
  }
  .dot:hover {
    width: 12.5px;
    height: 12.5px;
  }
  .dot.active {
    width: 12.5px;
    height: 12.5px;
  }
}

@media only screen and (max-width: 767px) {
  .slider {
    height: 60%;
  }
  .text {
    width: 70vw;
  }
}
