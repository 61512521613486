.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.headingContainer {
  margin: var(--mb-2) 0;
  margin-top: 6rem;
}

.headingContainer > h1 {
  font-family: "Stylish";
  text-transform: uppercase;
  font-size: 2.5rem;
}

.contentContainer {
  margin: var(--mb-2) 0;
  display: flex;
  justify-content: center;
  width: 75%;
}

.contentBox {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  padding: var(--mb-3);
  font-size: 1.2rem;
  font-stretch: expanded;
  line-height: 6vh;
  height: 60vh;
  overflow-y: scroll;
  border-radius: 10px;
  list-style: square;
  text-align: left;
}


.btn_s {
  font-family: "Primary";
  margin-top: 1rem;
  width: 100%;
  background-color: #e7e7e7;
  color: #080710;
  padding: 10px 0;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  padding: var(--mb-2);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .contentContainer {
    width: 90%;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .contentBox{
    font-size: 1rem;
  }
}
