.wrapper {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.infinityPath {
   --size: 60;
   --speed: 0.65;
   height: calc(var(--size) * 1px);
   position: relative;
   width: calc(var(--size) * 1px);
}
.infinityPath > div {
   height: calc(var(--size) * 1px);
   width: calc(var(--size) * 1px);
   border-radius: 100%;
   border: calc(var(--size) / 4 * 1px) solid rgba(255, 255, 255, 0.20);
   position: absolute;
   top: 0;
   left: 0;
   -webkit-animation-duration: calc(var(--speed) * 1s);
   animation-duration: calc(var(--speed) * 1s);
   -webkit-animation-timing-function: linear;
   animation-timing-function: linear;
   -webkit-animation-iteration-count: infinite;
   animation-iteration-count: infinite;
   -webkit-animation-name: infinity-spin;
   animation-name: infinity-spin;
   -webkit-transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0);
   transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0);
}
.infinityPath > div:before {
   content: '';
   height: calc(var(--size) / 4 * 1px);
   width: calc(var(--size) / 4 * 1px);
   border-radius: 100%;
   background: #cd3823;
   position: absolute;
   top: 50%;
   animation: infinity-vanish calc(var(--speed) * 2s) infinite reverse steps(1);
   -webkit-transform: translate(calc(var(--translate-2) * 2px), calc(var(--translate) * 1%));
   transform: translate(calc(var(--translate-2) * 2px), calc(var(--translate) * 1%));
}
.infinityPath > div:nth-of-type(1) {
   --translate: -50;
   --translate-2: calc(var(--size) / 8);
}
.infinityPath > div:nth-of-type(1):before {
   right: 0;
}
.infinityPath > div:nth-of-type(2) {
   --translate: 50;
   --translate-2: calc(var(--size) / 8 * -1);
   -webkit-animation-delay: calc(var(--speed) * 1s);
   animation-delay: calc(var(--speed) * 1s);
   animation-direction: reverse;
}
.infinityPath > div:nth-of-type(2):before {
   left: 0;
   -webkit-transform: translate(calc(var(--size) / 4 * -1px), -50%);
   transform: translate(calc(var(--size) / 4 * -1px), -50%);
   -webkit-animation-direction: normal;
   animation-direction: normal;
}

@-webkit-keyframes infinity-vanish {
   0% {
      opacity: 0;
   }
   50% {
      opacity: 1;
   }
}

@keyframes infinity-vanish {
   0% {
      opacity: 0;
   }
   50% {
      opacity: 1;
   }
}

@-webkit-keyframes infinity-spin {
   from {
      -webkit-transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0) rotate(0deg);
      transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0) rotate(0deg);
   }
   to {
      -webkit-transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0) rotate(360deg);
      transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0) rotate(360deg);
   }
}

@keyframes infinity-spin {
   from {
      -webkit-transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0) rotate(0deg);
      transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0) rotate(0deg);
   }
   to {
      -webkit-transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0) rotate(360deg);
      transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0) rotate(360deg);
   }
}
