.TimerContainer {
   font-family: 'Stylish', sans-serif;
   top: 1rem;
   right: 50%;
   transform: translateX(50%);
   position: absolute;
   z-index: 1;
   text-align: center;
   color: white;
   background-color: #e03c26;
   border-radius: 1rem;

   padding: 0.5rem;
}

.TimerContainer > p {
   font-size: 1.5rem;
   font-weight: 700;
}
.textOverlay {
   font-family: 'Stylish', sans-serif;
   color: white;
   width: 100%;
   display: flex;
   align-items: center;
   font-size: 20px;
   margin-top: 0.2rem;
   font-weight: 700;

   box-sizing: border-box;
}
.textOverlay div {
   display: flex;
   align-items: center;
   justify-content: center;
   flex: 1 0 auto;
   width: 5%;
}

@media only screen and (max-width: 767px) {
   .TimerContainer {
      font-size: 0.8rem;
      position: absolute;
      bottom: 0.5rem;
      top: unset;
      right: 0.5rem;
      z-index: 1;
      text-align: center;
      transform: unset;
      position: fixed;
      color: white;
      background-color: #e03c26;
      border-radius: 1rem;
      padding: 0.5rem;
   }

   .TimerContainer > p {
      font-size: 0.8rem;
   }

   .textOverlay {
      font-size: 0.8rem;
      margin-top: 0.2rem;
      font-weight: 700;
      box-sizing: border-box;
   }
   .textOverlay div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 0 auto;
      width: 5%;
   }
}
