.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  width: 50%;
  background-color: rgba(255,255,255,0.2);
  color: #fff;
  padding: 2rem 2rem;
  border-radius: 5px;
  text-align: center;
}

.contentContainer > h1 {
  font-family: "Stylish";
  text-transform: uppercase;
  font-size: 2rem;
  letter-spacing: 0.2rem;
}

.contentContainer > p {
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
}

.contentContainer h1 {
  margin: var(--mb-3) 0;
}

.contentContainer p {
  margin: var(--mb-2) 0;
}


@media only screen and (max-width: 767px) {

  .contentContainer {
  width: 70%;
  background-color: rgba(255,255,255,0.2);
  color: #fff;
  padding: 2rem 2rem;
  border-radius: 5px;
  text-align: center;
}
}
