.full_page {
   margin: auto;
   margin-top: 100px;
   text-align: center;
   align-items: center;
   justify-content: center;
}
.title {
   font-family: 'Stylish';
   color: white;
   text-transform: uppercase;
   font-size: 3rem;
}
.image {
   display: block;
   margin-left: auto;
   margin-right: auto;
   width: 75px;
   height: 75px;
}
.table {
   border-radius: 5px;
   display: grid;
   grid-template-columns: auto auto auto;
   color: #fff685;
   width: 85%;
   margin: auto;
   margin-top: 40px;
   margin-bottom: 40px;
   padding: 20px;
   background-color: rgba(255, 255, 255, 0.25);
   overflow-y: scroll;
   text-transform: uppercase;
   font-family: 'Stylish';
}

.record {
   padding: 10px;
   font-weight: 600;
   font-family: 'Primary';
}

.tableHead {
   font-family: 'Stylish';
   font-size: 1.4rem;
}

@media only screen and (max-width: 600px) {
   .title {
      font-size: 2rem;
   }
}
