@font-face {
  font-family: "Primary";
  src: url(./assets/fonts/Poppins-Regular.ttf );
}

@font-face {
  font-family: "PrimaryBold";
  src: url(./assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: "PoppinsMedium";
  src: url(./assets/fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: "Stylish";
  src: url(assets/fonts/nasalization-rg.otf);
}

