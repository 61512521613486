.formField {
   margin: var(--mb-2) 0;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid transparent;
   border-radius: 0.5rem;
   width: 100%;
}

.formField:nth-child(even) {
   background: linear-gradient(#262626, #262626) padding-box, linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
}

.formField:nth-child(odd) {
   background: linear-gradient(#262626, #262626) padding-box, linear-gradient(to left, var(--purple1), var(--orange1)) border-box;
}

.formField > input,
.formField > select,
.textarea {
   padding: var(--mb-2) 0;
   padding-right: var(--mb-2);
   border: none;
   background: none;
   outline: none;
   font-size: 1rem;
   color: var(--white);
   width: 100%;
   font-family: 'Primary', sans-serif;
}

.formField > select {
   -moz-appearance: none; /* Firefox */
   -webkit-appearance: none; /* Safari and Chrome */
   appearance: none;
   cursor: pointer;
}

.formField > select > option {
   background-color: var(--text);
   font-size: 1.1rem;
   margin: var(--mb-6);
   text-align: left;
}

.textarea {
   resize: vertical;
   font-family: var(--normalFont);
   height: 3rem;
   padding: var(--mb-1) var(--mb-2);
   max-height: 16rem;
}

.info {
   font-size: 0.8rem;
   text-align: left !important;
   opacity: 0.5;
}

.selectDiv {
   position: relative;
}

.selectDiv::after {
   content: '>';
   right: 11px;
   top: 6px;
   height: 34px;
   padding: 15px 0px 0px 8px;
   position: absolute;
   font-weight: 900;
   transform: rotateZ(90deg);
   cursor: pointer;
}

::placeholder {
   /* Chrome, Firefox, Opera, Safari 10.1+ */
   opacity: 1; /* Firefox */
   font-family: 'Primary', sans-serif;
}

:-ms-input-placeholder {
   /* Internet Explorer 10-11 */
   color: var(--text);
   font-family: 'Primary', sans-serif;
}

::-ms-input-placeholder {
   /* Microsoft Edge */
   color: var(--text);
   font-family: 'Primary', sans-serif;
}

.formFieldIcon {
   width: 25px;
   margin: 0 var(--mb-2);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
   .formField > input,
   .formField > select {
      width: 250px;
   }
}
