.container {
   /* background: black; */
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   position: fixed;
   top: 0px;
   left: 0px;
   color: #fff;
   width: 100%;
}

.sec {
   width: 30%;
   height: 30%;
   display: inline-block;
   text-align: center;
   min-height: 215px;
   vertical-align: top;
   margin: 1%;
   /* background: #080915;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 30px 1px #103136 inset;
            box-shadow: 0px 0px 30px 1px #103136 inset; */
}

@media only screen and (max-width: 600px) {
   .sec {
      min-width: 350px;
   }
}

.loader {
   position: relative;
   width: 60px;
   height: 60px;
   border-radius: 50%;
   margin: 75px;
   display: inline-block;
   vertical-align: middle;
}

.loader_1 .loader_outter {
   position: absolute;
   /* border: 4px solid #f50057; */
   border: 4px solid #911515;
   border-left-color: transparent;
   border-bottom: 0;
   width: 100%;
   height: 100%;
   border-radius: 50%;
   -webkit-animation: loader_1_outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
   animation: loader_1_outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

.loader_1 .loader_inner {
   position: absolute;
   /* border: 4px solid #f54500; */
   border: 4px solid #a74e2d;
   border-radius: 50%;
   width: 40px;
   height: 40px;
   left: calc(50% - 20px);
   top: calc(50% - 20px);
   border-right: 0;
   border-top-color: transparent;
   -webkit-animation: loader_1_inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
   animation: loader_1_inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

@-webkit-keyframes loader_1_outter {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

@keyframes loader_1_outter {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

@-webkit-keyframes loader_1_inner {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }
   100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
   }
}

@keyframes loader_1_inner {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }
   100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
   }
}
