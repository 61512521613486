.HeadingContainer {
   margin-top: 5rem;
   width: 100%;
   text-align: center;
}

.Heading {
   font-family: 'Stylish', sans-serif;
   font-size: 3.5rem;
   text-transform: uppercase;
   font-weight: 600;
   color: #fff;
   margin-bottom: 1rem;
}

.layout {
   display: block;
   background-color: rgba(255, 255, 255, 0.2);
   border-radius: 10px;
   padding: 1rem;
   width: 60%;
   margin: 0 auto;
   margin-top: 3rem;
   color: #fff;
}

.teamName {
   font-size: 1.2rem;
   background-color: #fff;
   color: #000;
   margin-bottom: 1rem;
   text-align: center;
   margin: auto;
   border-radius: 1rem;
   padding: 0.5rem 1rem;
   max-width: calc(60%);
}

.teamName > h2 {
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
}

.teamMembers {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   text-align: center;
   row-gap: 1rem;
   margin: 2rem auto;
}

.member {
   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 0.5rem;
}

.teamStats {
   display: flex;
   justify-content: space-around;
   flex-wrap: wrap;
   margin-top: 3rem;
   font-size: 1.5rem;
}

.teamScore,
.teamRank {
   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 0.5rem;
}

.teamScore > span,
.teamRank > span {
   font-family: 'Stylish', sans-serif;

   border-radius: 50%;
   padding: 1rem;
   width: 3.5rem;
   height: 3.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 2.6rem;
   font-weight: 600;
   margin: auto;
}

@media screen and (max-width: 768px) {
   .Heading {
      font-size: 2rem;
   }

   .layout {
      width: 90%;
   }

   .teamMembers {
      justify-content: center;
      flex-direction: column;
   }
   
   
   .teamName {
      max-width: calc(90%);
   }
}


