.menuToggle {
   display: block;
   position: fixed;
   top: 50px;
   left: 50px;
   z-index: 10;
   -webkit-user-select: none;
   user-select: none;
}

.menuToggle a {
   text-decoration: none;
   color: #232323;
   transition: color 0.3s ease;
}


.menuToggle input {
   display: block;
   width: 40px;
   height: 32px;
   position: absolute;
   top: -7px;
   left: -5px;

   cursor: pointer;

   opacity: 0; /* hide this */
   z-index: 2; /* and place it over the hamburger */

   -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
.menuToggle span {
   display: block;
   width: 33px;
   height: 4px;
   margin-bottom: 5px;
   position: relative;

   background: white;
   border-radius: 3px;

   z-index: 1;

   transform-origin: 4px 0px;

   transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

   background: 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

.menuToggle span:first-child {
   transform-origin: 0% 0%;
}

.menuToggle span:nth-last-child(2) {
   transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
.menuToggle input:checked ~ span {
   opacity: 1;
   transform: rotate(45deg) translate(-2px, -1px);
   background: white;
}

/*
 * But let's hide the middle one.
 */
.menuToggle input:checked ~ span:nth-last-child(3) {
   opacity: 0;
   transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
.menuToggle input:checked ~ span:nth-last-child(2) {
   transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
.menu {
   position: absolute;
   width: 300px;
   height: calc(100vh + 50px);
   margin: -100px 0 0 -50px;
   padding: 50px;
   padding-top: 125px;
   overflow: auto;

   background: #111;
   list-style-type: none;
   -webkit-font-smoothing: antialiased;
   /* to stop flickering of text in safari */
   text-align: left;
   transform-origin: 0% 0%;
   transform: translate(-100%, 0);
   transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.menu li {
   display: flex;
   flex-direction: row;
   align-items: center;
   color: aliceblue;
   padding: 10px 10px;
   font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
.menuToggle input:checked ~ ul {
   transform: none;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
   .menu {
      width: calc(100vw + 50px);
   }
   .menu li {
      padding-left: 50px;
   }
   .menuToggle {
      top: 25px;
      left: 25px;
   }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}
