.button {
    font-family: "Primary"  ;
    padding: 0.7rem;
    font-size: 1.2rem;
    color: var(--text);
    border-radius: 5px;
    transition: all 0.05s;
    width: max-content;
    text-align: center;
    border: none;
    margin-top: 1rem;
}

.button:hover {
    cursor: pointer;
    opacity: 0.8;
    transform: scale(0.95);
}