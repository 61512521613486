.align {
   width: 100vw;
   height: 100vh;
}

.object {
   visibility: hidden;
   /* animation: MoveUpDown 2s linear infinite; */
   position: absolute;
   top: 7vh;
   bottom: 75%;
}

@keyframes MoveUpDown {
   0%,
   100% {
      top: 8vh;
   }
   50% {
      top: 11vh;
   }
}

.flip_card {
   background-color: transparent;
   position: relative;
}

.flip_card_inner {
   position: relative;
   width: 50vw;
   height: 50vh;
   text-align: center;

   transition: transform 0.8s;

   transform-style: preserve-3d;
}

@keyframes Hoverout {
   100% {
      visibility: visible;
   }
}

.flip_card_front h1 {
   visibility: visible;
   transition: visibility 0s;
   transition-delay: 0.2s;
}
.flip_card:hover .flip_card_front h1 {
   visibility: hidden;
}

.flip_card_front,
.flip_card_back {
   position: absolute;
   width: 100%;
   height: 100%;
   -webkit-backface-visibility: hidden;
   backface-visibility: hidden;
}

.flip_card_front {
   background-color: rgba(145, 138, 138, 0.3);
   color: rgb(87, 13, 13);
   display: flex;
   justify-content: center;
   align-items: center;
}

.flip_card_front h1 {
   font-family: 'Stylish';
   color: rgb(255, 249, 249);
}

.flip_card_back {
   display: flex;
   flex-direction: column;
   justify-items: center;
   justify-content: center;
   align-items: center;
   background-color: rgba(145, 138, 138, 0.3);
   color: rgb(255, 249, 249);
   transform: rotateY(180deg);
   line-height: 8vh;
}

.flip_card_back h1 {
   color: white;
}
.align {
   width: 100vw;
   height: 100vh;
   display: flex;

   align-items: center;
   justify-content: center;
}


 .flip_card_back > li {
      font-size: 1.2rem;
   }


/* Above Tablet */
@media only screen and (min-width: 1024px) {
   .flip_card:hover .flip_card_inner {
      transform: rotateY(180deg);
   }
   
   .flip_card_front h1 {
      font-size: 2.5rem;
   }
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
   .flip_card_back li {
      font-size: 1rem;
   }
   .flip_card_front h1 {
      font-size: 2rem;
      visibility: hidden;
   }
   .flip_card_inner {
      width: 70vw;
      height: 70vh;
   }
   .flip_card {
      background-color: transparent;
      position: relative;
      top: 10vh;
   }
   .flip_card_front,
   .flip_card_back {
      -webkit-backface-visibility: visible;
      backface-visibility: visible;
      transform: rotateX(335deg);
   }

   .flip_card_back {
      /* height: inherit; */
   }

   .object {
      visibility: visible;
      animation: MoveUpDown 2s linear infinite;
      color: white;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100vw;
   }

   .object h1 {
      color: white;
      /* font-size: 6vh; */
      font-family: 'Stylish';
   }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
   .flip_card_back {
      visibility: visible;
      /* height: inherit; */
   }

   .flip_card_front {
      visibility: hidden;
   }

   .flip_card_front,
   .flip_card_back {
      -webkit-backface-visibility: visible;
      backface-visibility: visible;
      transform: rotateX(335deg);
   }
   
    .flip_card_back > h2 {
      font-size: 1.2rem;
   }
   
   .flip_card_back > li {
      font-size: 1rem;
   }
 
   .flip_card_front h1 {
      /* font-size: 5vw; */
      visibility: hidden;
   }
   .flip_card_inner {
      width: 70vw;
      height: 70vh;
   }
   .flip_card {
      background-color: transparent;
      position: relative;
      top: 10vh;
   }

   .object {
      visibility: visible;
      animation: MoveUpDown 2s linear infinite;
      color: white;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100vw;
   }

   .object h1 {
      color: white;
      /* font-size: 6vh; */
      font-family: 'Stylish';
   }
}
