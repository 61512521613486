.App {
   text-align: center;
   background-attachment: fixed;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   color: #fff;
   background-size: cover;
   height: 100vh;
   margin: 0px;
   padding: 0px;
}

.Klogo {
   /* height: 12vh !important; */
   width: 200px !important;
   position: absolute;
   top: 3vh;
   right: 3vw;
   z-index: 1;
   image-rendering: -moz-crisp-edges; /* Firefox */
   image-rendering: -o-crisp-edges; /* Opera */
   image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
   image-rendering: crisp-edges;
   -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   background-color: #2d2d2d;
   
}

::-webkit-scrollbar {
   width: 5px;
}

::-webkit-scrollbar-thumb {
   border-radius: 1rem;
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   background-color: #999;
   
}

.colorPaletteGradientBackground,
.colorPaletteGradientBackgroundHover:hover {
   background: var(--orange2);
   background: -moz-linear-gradient(45deg, var(--orange2) 0%, var(--orange1) 25%, var(--red1) 50%, var(--pink2) 75%, var(--pink1) 100%);
   background: -webkit-linear-gradient(45deg, var(--orange2) 0%, var(--orange1) 25%, var(--red1) 50%, var(--pink2) 75%, var(--pink1) 100%);
   background: linear-gradient(45deg, var(--orange2) 0%, var(--orange1) 25%, var(--red1) 50%, var(--pink2) 75%, var(--pink1) 100%);
}

/* Mobile only */
@media only screen and (max-width: 767px) {
   .Klogo {
      width: 150px;
   }
}

