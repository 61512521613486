.wrapper {
   height: 100vh;
}

.Heading {
   font-family: 'Stylish';
   font-size: 5rem;
   text-transform: uppercase;
   color: white;
   text-align: center;
   letter-spacing: 10px;
}

.HeadingContainer {
   position: relative;
   top: 17%;
}


.layoutTab {
   display: inline-flex;
   width: 20vw;
   height: 30vh;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   text-align: center;
}
.btnImage {
   height: 20vh;
   width: 10vw;
}
.btnImage:hover,
.MGlass:hover {
   transform: scale(1.3);
}
.btnContent {
   color: white;
   text-align: center;
   font-size: 1.5rem;
   margin-top: 6%;
}
.layout {
   position: relative;
   top: 27%;
   background-color: rgba(255, 255, 255, 0.2);
   width: fit-content;
   left: 50%;
   transform: translateX(-50%);
   border-radius: 10px;
   padding: 1rem;
}
.centreAlign {
   display: flex;
   flex-direction: row;
   justify-content: center;
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
   .Heading {
      font-size: 5rem;
   }

   .btnImage {
      height: 20vh;
      width: 15vw;
   }

   .btnContent {
      font-size: 1.5rem;
      color: white;
      text-align: center;
   }

   .layoutTab {
      height: fit-content;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 2vh;
   }
   .centreAlign {
      display: flex;
      align-items: center;
      justify-content: center;
   }
   .layout {
      position: relative;
      top: 35%;
      padding: 1rem;
   }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
   .btnImage {
      height: 15vh;
      width: 35vw;
   }

   .btnContent {
      font-size: 1.2rem;
      color: white;
      text-align: center;
      /* margin-bottom: 2vh; */
   }

   .centreAlign {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
   }
   .layout {
      position: relative;
      top: 18%;
      padding-inline: 5rem;
   }

   .Heading {
      font-size: 4rem;
   }
   .HeadingContainer {
      top: 15%;
   }
}
